import React, { Component } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import firebase from 'firebase'
import Gallery from './Gallery'
import App from '../App'
import Inventory from './Inventory'
import base from '../base'
import Login from './Login'
import Nav from './Nav'
import { ParallaxProvider } from 'react-scroll-parallax'
import Footer from './Footer'
import Sponsors from './Sponsors'
import Shop from './Shop'

export default class RouterComp extends Component {
  state = {
    cabinet: {},
    sponsor: {},
    board: {},
    order: {},
    user: null,
    login: false
  }

  componentDidMount () {
    document.addEventListener('resize', this.resize)

    const localStorageRef = localStorage.getItem('order')
    const user = localStorage.getItem('uid')

    if (user) {
      let expiresAt = JSON.parse(localStorage.getItem('expires_at'))
      if (new Date().getTime() < expiresAt) {
        this.setState({ user: user })
      } else {
        localStorage.removeItem('uid')
        localStorage.removeItem('expires_at')
      }
    }

    if (localStorageRef) {
      this.setState({ order: JSON.parse(localStorageRef) })
    }

    this.ref = base.syncState('cabinet', {
      context: this,
      state: 'cabinet',
      isArray: false
    })
    this.ref = base.syncState('sponsor', {
      context: this,
      state: 'sponsor',
      isArray: false
    })
    this.ref = base.syncState('board', {
      context: this,
      state: 'board',
      isArray: false
    })
  }

  componentDidUpdate () {
    localStorage.setItem('order', JSON.stringify(this.state.order))
  }

  componentWillUnmount () {
    base.removeBinding(this.ref)
  }

  login = (email, password) => {
    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(() => {
        // Existing and future Auth states are now persisted in the current
        // session only. Closing the window would clear any existing state even
        // if a user forgets to sign out.
        // New sign-in will be persisted with session persistence.
        return firebase
          .auth()
          .signInWithEmailAndPassword(email, password)
          .then(data => {
            let expiresAt = JSON.stringify(86400 + new Date().getTime())
            localStorage.setItem('uid', data.user.uid)
            localStorage.setItem('expires_at', expiresAt)
            this.setState({ user: data.user.uid })
            this.toggleLogin()
          })
          .catch(function (error) {
            // Handle Errors here.
            var errorCode = error.code
            var errorMessage = error.message
            console.log(errorCode, errorMessage)
            if (errorCode === 'auth/wrong-password') {
              alert('Wrong password.')
            } else {
              alert(errorMessage)
            }
            console.log(error)
          })
        // creatin a user
        // else {
        //   return firebase
        //     .auth()
        //     .createUserWithEmailAndPassword(email, password)
        //     .then(data => {
        //       console.group(data)
        //     })
        //     .catch(function (error) {
        //       // Handle Errors here.
        //       var errorCode = error.code
        //       var errorMessage = error.message
        //       console.log(errorCode, errorMessage)
        //       if (errorCode === 'auth/weak-password') {
        //         alert('The password is too weak.')
        //       } else {
        //         alert(errorMessage)
        //       }
        //       console.log(error)
        //     })
        // }
      })
      .catch(function (error) {
        var errorMessage = error.message
        alert(errorMessage)
      })
  }

  // CABINET
  addCabinet = item => {
    const cabinet = { ...this.state.cabinet }

    cabinet[`cabinet${Date.now()}`] = item

    this.setState({ cabinet })
  }

  updateCabinet = (key, updatedItem) => {
    const cabinet = { ...this.state.cabinet }

    cabinet[key] = updatedItem

    this.setState({ cabinet })
  }

  deleteCabinet = key => {
    const cabinet = { ...this.state.cabinet }

    cabinet[key] = null

    this.setState({ cabinet })
  }
  // CABINET

  // BOARD
  addBoard = item => {
    const board = { ...this.state.board }

    board[`board${Date.now()}`] = item

    this.setState({ board })
  }

  updateBoard = (key, updatedItem) => {
    const board = { ...this.state.board }

    board[key] = updatedItem

    this.setState({ board })
  }

  deleteBoard = key => {
    const board = { ...this.state.board }

    board[key] = null

    this.setState({ board })
  }
  // BOARD

  // Sponsor
  addSponsor = item => {
    const sponsor = { ...this.state.sponsor }

    sponsor[`sponsor${Date.now()}`] = item

    this.setState({ sponsor })
  }

  updateSponsor = (key, updatedItem) => {
    const sponsor = { ...this.state.sponsor }

    sponsor[key] = updatedItem

    this.setState({ sponsor })
  }

  deleteSponsor = key => {
    const sponsor = { ...this.state.sponsor }

    sponsor[key] = null

    this.setState({ sponsor })
  }
  // Sponsor

  addToCart = key => {
    const order = { ...this.state.order }

    order[key] = order[key] + 1 || 1

    this.setState({ order })
  }

  deleteFromCart = key => {
    const order = { ...this.state.order }

    delete order[key]

    this.setState({ order })
  }

  logout = () => {
    firebase.auth().signOut()
    localStorage.removeItem('uid')
    localStorage.removeItem('expires_at')
    this.setState({ user: null })
  }
  toggleLogin = () => {
    this.setState({ login: !this.state.login })
  }

  render () {
    return (
      <BrowserRouter>
        <Switch>
          <ParallaxProvider>
            <Nav
              mobile={this.state.mobile}
              toggleLogin={this.toggleLogin}
              logout={this.logout}
              user={this.state.user}
              scopeCheck={this.scopeCheck}
            />
            <Route
              exact
              path='/'
              render={props => {
                return (
                  <App
                    items={this.state.items}
                    order={this.state.order}
                    addToCart={this.addToCart}
                    deleteFromCart={this.deleteFromCart}
                    cabinet={this.state.cabinet}
                  />
                )
              }}
            />

            <Route
              exact
              path='/sponsors'
              render={props => <Sponsors sponsors={this.state.sponsor} />}
            />
            <Route exact path='/gallery' render={props => <Gallery />} />

            <Route
              exact
              path='/inventory'
              render={props => {
                return (
                  <Inventory
                    user={this.state.user}
                    cabinet={this.state.cabinet}
                    board={this.state.board}
                    sponsor={this.state.sponsor}
                    addCabinet={this.addCabinet}
                    updateCabinet={this.updateCabinet}
                    deleteCabinet={this.deleteCabinet}
                    addBoard={this.addBoard}
                    updateBoard={this.updateBoard}
                    deleteBoard={this.deleteBoard}
                    addSponsor={this.addSponsor}
                    updateSponsor={this.updateSponsor}
                    deleteSponsor={this.deleteSponsor}
                  />
                )
              }}
            />
            <Route
              exact
              path='/shop'
              render={props => {
                return (
                  <div className='shop'>
                    <h2>shop</h2>
                    <Shop />
                  </div>
                )
              }}
            />
            <Footer />
            <Login
              userLogin={this.login}
              toggleLogin={this.toggleLogin}
              open={this.state.login}
            />
          </ParallaxProvider>
        </Switch>
      </BrowserRouter>
    )
  }
}
