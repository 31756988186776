import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BFLogo from '../css/images/BFCLogo.png'

export default class Logo extends Component {
    render() {
        return (
            <div className="top" onClick={this.props.scrollToTop}>
                <Link to="/" className="logolink">
                    <div className="logo">
                        <img className="logoimg" src={BFLogo} alt="Boerne Bass Club Logo" />
                    </div>
                    <span id="tagline">
                    {/* <span id="fc">Bass Club</span> */}
                    </span>
                </Link>
            </div>
        )
    }
}
