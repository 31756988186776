import React, { Component } from 'react'
import { fetchPhotos } from '../utils/Cloudinary'
import { Image } from 'cloudinary-react'
export default class Sponsor extends Component {
  state = {
    sponsor: []
  }
  componentDidMount () {
    fetchPhotos('sponsor').then(photos => {
      this.setState({
        type: 'PHOTOS_FETCHED',
        sponsor: photos
      })
    })
  }
  render () {
    return (
      <div className='sponsor'>
        <h2>Sponsors</h2>
        <div className='sponsor-content'>
          {this.state.sponsor.map(image => (
            <Image
              className='sponsor-img fadein'
              key={image.public_id}
              cloudName='boernebass'
              publicId={image.public_id}
              alt={image.public_id}
            />
          ))}
        </div>
      </div>
    )
  }
}
