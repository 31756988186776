import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Logo from './Logo'
import HamburgerButton from './HamburgerButton'

export default class Nav extends Component {
  state = {
    visible: false,
    open: false,
    admin: false,
    lett: []
  }

  componentDidMount () {
    window.addEventListener('keyup', this.check)
    window.addEventListener('scroll', this.scroll)
  }
  componentWillUnmount () {
    window.removeEventListener('keyup', this.check)
    window.removeEventListener('scroll', this.scroll)
  }
  scroll = e => {
    if (
      window.pageYOffset >
      (document.querySelector('.fishheader') || {}).clientHeight - 69
    ) {
      this.setState({ scroll: true })
    } else {
      this.setState({ scroll: false })
    }
  }

  check = e => {
    if (!e.code) return
    if (e.code.slice(0, -1) === 'Key') {
      const words = this.state.lett.join('')
      const letter = e.code[e.code.length - 1]
      if ((words + letter).includes('JEDBOERNEBASSCLUB')) {
        this.setState({ visible: !this.state.visible })
      }
      this.setState({ lett: [...this.state.lett, letter] })
    }
  }

  toggle = () => {
    this.setState({ open: !this.state.open })
  }
  closeNav = () => {
    this.setState({ open: false })
  }
  render () {
    return (
      <nav className={`nav`}>
        <div className='shopping-cart' />
        <Logo scrollToTop={this.props.scrollToTop} />
        <div className='nav-toggle' onClick={this.toggle}>
          <HamburgerButton open={this.state.open} />
        </div>
        <ul
          onClick={this.closeNav}
          className={`closed ${this.state.open ? 'open' : ''}`}
        >
          <Link className='nav-button' to='/'>
            Home
          </Link>
          <Link className='nav-button' to='shop'>
            Shop
          </Link>
          {this.props.user && (
            <Link className='nav-button' to='/inventory'>
              Inventory
            </Link>
          )}
          <Link to='/gallery' className='nav-button'>
            Gallery
          </Link>
          <Link to='/sponsors' className='nav-button'>
            Sponsors
          </Link>
          {!this.props.user ? (
            <button className='nav-button' onClick={this.props.toggleLogin}>
              Login
            </button>
          ) : (
            <button onClick={this.props.logout} className='nav-button'>
              Logout
            </button>
          )}
        </ul>
      </nav>
    )
  }
}
