import React, { Component } from 'react'

export default class AddBoardForm extends Component {
  state = {
    name: '',
    desc: '',
    image: '',
    order: ''
  }

  createItem = e => {
    e.preventDefault()

    this.props.addCabinet(this.state)

    this.setState({
      name: '',
      desc: '',
      image: '',
      order: ''
    })
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  render () {
    return (
      <form className='item-edit cabinet-edit' onSubmit={this.createItem}>
        <input
          required
          name='name'
          type='text'
          placeholder='Name'
          value={this.state.name}
          onChange={this.handleChange}
        />
        <input
          required
          type='text'
          name='desc'
          placeholder='Desc'
          value={this.state.desc}
          onChange={this.handleChange}
        />
        <input
          required
          name='image'
          type='text'
          placeholder='Image'
          value={this.state.image}
          onChange={this.handleChange}
        />
        <input
          required
          name='order'
          type='number'
          placeholder='Order'
          value={this.state.order}
          onChange={this.handleChange}
        />
        <button type='submit'>Add Cabinet Member!</button>
      </form>
    )
  }
}
