import React, { Component } from 'react'
import EditBoard from './EditBoard'
import AddBoardForm from './AddBoardForm'

export default class Board extends Component {
  render () {
    return (
      <>
        {Object.keys(this.props.board).map(key => (
          <EditBoard
            key={key}
            llave={key}
            item={this.props.board[key]}
            updateBoard={this.props.updateBoard}
            deleteBoard={this.props.deleteBoard}
          />
        ))}
        <AddBoardForm addBoard={this.props.addBoard} />
      </>
    )
  }
}
