import React, { Component } from 'react'

export default class Contact extends Component {
  state = {
    name: '',
    email: '',
    subject: '',
    message: ''
  }

  handleChange = e => {
    this.setState({ [e.target]: e.value })
  }

  handleSubmit = e => {
    e.preventDefault()
  }
  render () {
    return (
      <div className='contact-form' ref={this.props.contactref}>
        <form
          method='POST'
          action='https://formspree.io/boernebass@gmail.com'
        >
          <label htmlFor='name'>Name:</label>
          <input required type='text' name='name' onChange={this.handleChange} />
          <label htmlFor='email'>Email:</label>
          <input required type='text' name='email' onChange={this.handleChange} />
          <label htmlFor='subject'>Subject:</label>
          <input required type='text' name='subject' onChange={this.handleChange} />
          <label required htmlFor='message'>Message:</label>
          <textarea name='message' onChange={this.handleChange} />
          <div className='btn-sub'>
            <button type='submit'>Send</button>
          </div>
        </form>
      </div>
    )
  }
}
