import React, { Component } from 'react'
import { fetchPhotos } from '../utils/Cloudinary'
import { Image } from 'cloudinary-react'
export default class Sponsor extends Component {
  state = {
    sponsor: []
  }
  componentDidMount () {
    fetchPhotos('mainsponsor')
      .then(photos => {
        this.setState({
          type: 'PHOTOS_FETCHED',
          sponsor: photos
        })
      })
      .catch(err => console.log(err))
  }
  render () {
    return (
      <div className='sponsor-content'>
        {this.state.sponsor.map(image => (
          <Image
            className='sponsor-img'
            key={image.public_id}
            cloudName='boernebass'
            publicId={image.public_id}
            alt={image.public_id}
          />
        ))}
      </div>
    )
  }
}
