import React, { Component } from 'react'

export default class EditItem extends Component {
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })

    const updatedItem = {
      ...this.props.cabinet,
      [e.target.name]: e.target.value
    }

    this.props.updateBoard(this.props.llave, updatedItem)
  }

  render () {
    const { item, llave } = this.props

    return (
      <div className='item-edit'>
        <input
          type='text'
          name='name'
          value={item.name}
          onChange={this.handleChange}
        />
        <input
          required
          type='text'
          name='desc'
          value={item.desc}
          onChange={this.handleChange}
        />
        <input
          type='text'
          name='image'
          value={item.image}
          onChange={this.handleChange}
        />
        <input
          required
          name='order'
          type='number'
          placeholder='Order'
          value={item.order}
          onChange={this.handleChange}
        />
        <button
          className='warning'
          onClick={() => this.props.deleteBoard(llave)}
        >
          Delete item
        </button>
      </div>
    )
  }
}
