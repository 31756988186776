import React from 'react'

const Footer = () => (
  <footer>
    <svg viewBox='0 0 120 20'>
      <defs>
        <path
          id='wave'
          d='M 0,10 C 30,10 30,15 60,15 90,15 90,10 120,10 150,10 150,15 180,15 210,15 210,10 240,10 v 28 h -240 z'
        />
      </defs>

      <path
        id='wave2'
        className='wave'
        d='M 0,10 C 30,10 30,15 60,15 90,15 90,10 120,10 150,10 150,15 180,15 210,15 210,10 240,10 v 28 h -240 z'
      />
      <use id='wave3' className='wave' x='0' y='-2' />
      <path
        id='wave3'
        className='wave'
        d='M 0,10 C 30,10 30,15 60,15 90,15 90,10 120,10 150,10 150,15 180,15 210,15 210,10 240,10 v 28 h -240 z'
      />
      <use id='wave2' className='wave' x='0' y='0' />

      <use id='wave1' className='wave' x='0' y='1' />
      <path
        id='wave1'
        className='wave'
        d='M 0,10 C 30,10 30,15 60,15 90,15 90,10 120,10 150,10 150,15 180,15 210,15 210,10 240,10 v 28 h -240 z'
      />
    </svg>
    <div className='footer-content'>
      <div>
        <p>&copy;BoerneBassClub 2019</p>
      </div>
      <a href='https://kevinpena.io' target='_blank' rel='noopener noreferrer'>
        Made with <span role='img' aria-label='heart emoji'>❤️</span> in SMTX
      </a>
    </div>
  </footer>
)
export default Footer
