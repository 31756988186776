import React, { Component } from 'react'
import axios from 'axios'
import Board from './Board'
import Cabinet from './Cabinet'

function makeid (length) {
  var result = ''
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  var charactersLength = characters.length
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export default class Inventory extends Component {
  state = {
    error: false,
    images: [],
    files: [],
    folder: false,
    cabtag: false
  }

  uploadFile = async e => {
    const files = Array.from(e.target.files, x => x)

    const images = files.map(file => URL.createObjectURL(file))

    this.setState({ files, images })
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    this.setState({
      [name]: value
    })
  }

  toCloudinary = async e => {
    e.preventDefault()
    const { files, folder, cabtag } = this.state

    if (cabtag && folder) {
      alert('ERROR: CANNOT HAVE TWO OPTIONS SELECTED. TRY AGAIN')
      this.setState({ folder: '', cabtag: '' })
      return
    } else if (!cabtag && !folder) {
      alert('ERROR: TAG IS REQUIRED')
      return
    }

    // eslint-disable-next-line
    for (const file of files) {
      const data = new FormData()
      const tosign = {}
      data.append('upload_preset', 'production')
      tosign['upload_preset'] = 'production'

      const tags = []
      if (folder) {
        const rand = makeid(20)
        tags.push(folder)
        data.append('folder', folder)
        data.append('public_id', rand)
        tosign['public_id'] = rand
        tosign['folder'] = folder
      } else if (cabtag) {
        data.append('folder', 'home')
        data.append('public_id', cabtag)
        tosign['public_id'] = cabtag
        tosign['folder'] = 'home'
        tags.push('production')
      }
      data.append('tags', tags)
      data.append('invalidate', 'true')
      tosign['invalidate'] = 'true'
      tosign['tags'] = tags.join()

      // let config = {
      //   headers: {
      //     'Access-Control-Allow-Origin': '*',
      //     'Access-Control-Allow-Methods': 'POST',
      //     'Access-Control-Allow-Headers': 'Content-Type'
      //   }
      // }
      const {
        data: { params, token }
      } = await axios.post('https://server.boernebassclub.com', tosign)

      data.append('timestamp', params.timestamp)
      data.append('api_key', '885311148289126')
      data.append('file', file)
      data.append('signature', token)

      fetch('https://api.cloudinary.com/v1_1/boernebass/image/upload', {
        method: 'POST',
        body: data
      })
        .catch(err => {
          console.log(err)
          alert('INTERNAL ERROR: TRY AGAIN')
        })
        .then(res => {
          this.setState({
            images: [],
            files: [],
            folder: false,
            cabtag: false,
            error: false
          })
        })
    }
  }

  render () {
    return (
      <div className='inventory item-edit grid2'>
        <h2>Inventory</h2>

        <div className='images'>
          <div className='image-gallery-prev'>
            {this.state.images.map((img, i) => (
              <img key={`upload ${i}`} src={img} alt={`upload ${i}`} />
            ))}
          </div>
          {this.props.user && (
            <form className='cloudinary-form'>
              <label htmlFor='File'>
                Image Upload:
                <input
                  type='file'
                  id='file'
                  name='file'
                  multiple
                  placeholder='Upload image(s)'
                  onChange={this.uploadFile}
                  required
                />
              </label>
              <div>
                <label>
                  Select Tag:
                  <select
                    name='folder'
                    className='not'
                    id='folder'
                    onChange={this.handleInputChange}
                    value={this.state.folder}
                  >
                    <option value=''>Sponsor or Gallery</option>
                    <option value='sponsor'>Sponsor</option>
                    <option value='gallery'>Gallery</option>
                    <option value='mainsponsor'>Main Sponsor</option>
                  </select>
                  <span>OR</span>
                  <select
                    onChange={this.handleInputChange}
                    name='cabtag'
                    value={this.state.cabtag}
                    className='not'
                    id='cabtag'
                  >
                    <option value=''>Home Page</option>
                    <option value='header'>Header</option>
                    <option value='group'>Group</option>
                    <option value='contact'>Contact</option>
                    <option value='schedule'>Schedule</option>
                    <option value='president'>President</option>
                    <option value='vicepresident'>Vice President</option>
                    <option value='treasurer'>Treasurer</option>
                    <option value='secretary'>Secretary</option>
                    <option value='reporter'>Reporter</option>
                  </select>
                </label>
                {/* <br />
              <input
                name='mainsponsor'
                type='checkbox'
                className='tag'
                value='mainsponsor'
                onChange={this.handleInputChange}
              />
              <label htmlFor='mainsponsor'>Main Sponsor</label> */}
              </div>
              <button onClick={this.toCloudinary}>Upload Images</button>
            </form>
          )}
        </div>

        <div className='cabinet'>
          <h3>Edit Cabinet</h3>
          <Cabinet
            addCabinet={this.props.addCabinet}
            updateCabinet={this.props.updateCabinet}
            deleteCabinet={this.props.deleteCabinet}
            cabinet={this.props.cabinet}
          />
        </div>
        <div className='board'>
          <h3>Edit Board</h3>
          <Board
            addBoard={this.props.addBoard}
            updateBoard={this.props.updateBoard}
            deleteBoard={this.props.deleteBoard}
            board={this.props.board}
          />
        </div>
      </div>
    )
  }
}
