import React, { Component } from 'react'
import AddCabinetForm from './AddCabinetForm'
import EditCabinet from './EditCabinet'

class Cabinet extends Component {
  render () {
    return (
      <>
        {Object.keys(this.props.cabinet).map(key => (
          <EditCabinet
            key={key}
            llave={key}
            item={this.props.cabinet[key]}
            updateCabinet={this.props.updateCabinet}
            deleteCabinet={this.props.deleteCabinet}
          />
        ))}
        <AddCabinetForm addCabinet={this.props.addCabinet} />
      </>
    )
  }
}
export default Cabinet
