import Rebase from 're-base'
import firebase from 'firebase/app'

var firebaseApp = firebase.initializeApp({
  apiKey: 'AIzaSyCbb7L1NLhTVCsalcaCmN8aKyWw1FdUhxM',
  authDomain: 'boernebassclub.firebaseapp.com',
  databaseURL: 'https://boernebassclub.firebaseio.com',
  projectId: 'boernebassclub',
  storageBucket: 'boernebassclub.appspot.com',
  messagingSenderId: '853644647912',
  appId: '1:853644647912:web:a02881b902e517620b585d'
})

const base = Rebase.createClass(firebaseApp.database())

export default base
