import React, { useState } from 'react'
import Carousel from 'react-bootstrap/Carousel'
import styled from 'styled-components'
// import president from '../css/images/president.svg'
// import vicepresident from '../css/images/vicepresident.svg'
// import treasurer from '../css/images/treasurer.svg'
// import secretary from '../css/images/secretary.svg'
// import reporter from '../css/images/reporter.svg'

const Styled = styled.div`
  .carousel {
    position: relative;
  }
  .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 10px;
    left: 0;
    z-index: 2;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
    li {
      position: relative;
      -ms-flex: 0 1 auto;
      flex: 0 1 auto;
      width: 30px;
      height: 3px;
      margin-right: 3px;
      margin-left: 3px;
      text-indent: -999px;
      cursor: pointer;
      background-color: rgba(255, 255, 255, 0.5);
    }
    .active {
      background-color: #fff;
    }
  }
  .carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  .carousel-item {
    position: relative;
    display: none;
    align-items: center;
    width: 100%;
    backface-visibility: hidden;
    perspective: 1000px;
  }
  .carousel-item.active,
  .carousel-item-next,
  .carousel-item-prev {
    display: block;
  }
  .carousel-item-next,
  .carousel-item-prev {
    position: absolute;
    top: 0;
  }
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    transform: translateX(0);
    @supports (transform-style: preserve-3d) {
      transform: translate3d(0, 0, 0);
    }
  }
  .carousel-item-next,
  .active.carousel-item-right {
    transform: translateX(100%);
    @supports (transform-style: preserve-3d) {
      transform: translate3d(100%, 0, 0);
    }
  }
  .carousel-item-prev,
  .active.carousel-item-left {
    transform: translateX(-100%);
    @supports (transform-style: preserve-3d) {
      transform: translate3d(-100%, 0, 0);
    }
  }
  .carousel-control-prev {
    left: 0;
  }
  .carousel-control-next,
  .carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: 0.5;
  }
  .carousel-control-next {
    right: 0;
  }
  .carousel-control-prev-icon {
    background-image: url('/left.svg') !important;
    transform: scale(2);
  }
  .carousel-control-next-icon {
    background-image: url('/right.svg') !important;
    transform: scale(2);
  }
  .sr-only {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: transparent no-repeat center center;
    background-size: 100% 100%;
  }
  .carousel-fade {
    .carousel-item {
      opacity: 0;
      transition-duration: 0.6s;
      transition-property: opacity;
    }
    .carousel-item.active,
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      opacity: 1;
    }
    .active.carousel-item-left,
    .active.carousel-item-right {
      opacity: 0;
    }
    .carousel-item-next,
    .carousel-item-prev,
    .carousel-item.active,
    .active.carousel-item-left,
    .active.carousel-item-prev {
      transform: translateX(0);
      @supports (transform-style: preserve-3d) {
        transform: translate3d(0, 0, 0);
      }
    }
  }
`

export default function Cab (props) {
  const [index, setIndex] = useState(0)
  const [direction, setDirection] = useState(null)

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex)
    setDirection(e.direction)
  }
  const { cabinet } = props

  const mems = Object.keys(cabinet).map(cab => (
    <Carousel.Item key={cab}>
      <div className='member-image'>
        <img alt={cabinet[cab].desc} src={cabinet[cab].image} />
      </div>
      <Carousel.Caption>
        <h3 className='position-name'>
          {cabinet[cab].desc}
          <span className='right'>{cabinet[cab].name}</span>
        </h3>
      </Carousel.Caption>
    </Carousel.Item>
  ))

  return (
    <Styled>
      <div className='team-captains'>
        <div className='whole-team'>
          <div className='team-positions'>
            <Carousel
              activeIndex={index}
              direction={direction}
              onSelect={handleSelect}
              fade
            >
              {mems}
            </Carousel>
          </div>
        </div>
      </div>
    </Styled>
  )
}
