import React, { Component } from 'react'

export default class Shop extends Component {
  componentDidMount () {
    ;(function () {
      var scriptURL =
        'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js'
      if (window.ShopifyBuy) {
        if (window.ShopifyBuy.UI) {
          ShopifyBuyInit()
        } else {
          loadScript()
        }
      } else {
        loadScript()
      }
      function loadScript () {
        var script = document.createElement('script')
        script.async = true
        script.src = scriptURL
        ;(
          document.getElementsByTagName('head')[0] ||
          document.getElementsByTagName('body')[0]
        ).appendChild(script)
        script.onload = ShopifyBuyInit
      }
      function ShopifyBuyInit () {
        var client = window.ShopifyBuy.buildClient({
          domain: 'bunker-branding-co.myshopify.com',
          storefrontAccessToken: '72c671d7c3491483642f8be588482f83'
        })
        window.ShopifyBuy.UI.onReady(client).then(function (ui) {
          ui.createComponent('collection', {
            id: '198912016515',
            node: document.getElementById('collection-component-1611178677078'),
            moneyFormat: '%24%7B%7Bamount%7D%7D',
            options: {
              product: {
                styles: {
                  product: {
                    '@media (min-width: 601px)': {
                      'max-width': 'calc(33.33333% - 30px)',
                      'margin-left': '30px',
                      'margin-bottom': '50px',
                      width: 'calc(33.33333% - 30px)'
                    },
                    img: {
                      height: 'calc(100% - 15px)',
                      position: 'absolute',
                      left: '0',
                      right: '0',
                      top: '0'
                    },
                    imgWrapper: {
                      'padding-top': 'calc(75% + 15px)',
                      position: 'relative',
                      height: '0'
                    }
                  },
                  title: {
                    'font-family': 'Roboto, sans-serif',
                    'font-size': '17px',
                    color: '#282828'
                  },
                  button: {
                    'font-family': 'Roboto, sans-serif',
                    'font-size': '17px',
                    'padding-top': '16.5px',
                    'padding-bottom': '16.5px',
                    ':hover': {
                      'background-color': '#000000'
                    },
                    'background-color': '#000000',
                    ':focus': {
                      'background-color': '#000000'
                    },
                    'border-radius': '0px',
                    'padding-left': '20px',
                    'padding-right': '20px'
                  },
                  quantityInput: {
                    'font-size': '17px',
                    'padding-top': '16.5px',
                    'padding-bottom': '16.5px'
                  },
                  price: {
                    'font-family': 'Karla, sans-serif',
                    'font-size': '18px',
                    color: '#7e7e7e'
                  },
                  compareAt: {
                    'font-family': 'Karla, sans-serif',
                    'font-size': '15.299999999999999px',
                    color: '#7e7e7e'
                  },
                  unitPrice: {
                    'font-family': 'Karla, sans-serif',
                    'font-size': '15.299999999999999px',
                    color: '#7e7e7e'
                  }
                },
                buttonDestination: 'modal',
                contents: {
                  options: false
                },
                text: {
                  button: 'View product'
                },
                googleFonts: ['Roboto', 'Karla']
              },
              productSet: {
                styles: {
                  products: {
                    '@media (min-width: 601px)': {
                      'margin-left': '-30px'
                    }
                  }
                }
              },
              modalProduct: {
                contents: {
                  img: false,
                  imgWithCarousel: true,
                  button: false,
                  buttonWithQuantity: true
                },
                styles: {
                  product: {
                    '@media (min-width: 601px)': {
                      'max-width': '100%',
                      'margin-left': '0px',
                      'margin-bottom': '0px'
                    }
                  },
                  button: {
                    'font-family': 'Roboto, sans-serif',
                    'font-size': '17px',
                    'padding-top': '16.5px',
                    'padding-bottom': '16.5px',
                    ':hover': {
                      'background-color': '#000000'
                    },
                    'background-color': '#000000',
                    ':focus': {
                      'background-color': '#000000'
                    },
                    'border-radius': '0px',
                    'padding-left': '20px',
                    'padding-right': '20px'
                  },
                  quantityInput: {
                    'font-size': '17px',
                    'padding-top': '16.5px',
                    'padding-bottom': '16.5px'
                  },
                  title: {
                    'font-family': 'Karla, sans-serif',
                    'font-weight': 'normal'
                  },
                  price: {
                    'font-family': 'Karla, sans-serif',
                    'font-size': '20px'
                  },
                  compareAt: {
                    'font-family': 'Karla, sans-serif',
                    'font-size': '17px'
                  },
                  unitPrice: {
                    'font-family': 'Karla, sans-serif',
                    'font-size': '17px'
                  },
                  description: {
                    'font-family': 'Karla, sans-serif'
                  }
                },
                googleFonts: ['Karla', 'Roboto']
              },
              option: {
                styles: {
                  label: {
                    'font-family': 'Karla, sans-serif',
                    'font-size': '17px'
                  },
                  select: {
                    'font-family': 'Karla, sans-serif'
                  }
                },
                googleFonts: ['Karla']
              },
              cart: {
                styles: {
                  button: {
                    'font-family': 'Roboto, sans-serif',
                    'font-size': '17px',
                    'padding-top': '16.5px',
                    'padding-bottom': '16.5px',
                    ':hover': {
                      'background-color': '#000000'
                    },
                    'background-color': '#000000',
                    ':focus': {
                      'background-color': '#000000'
                    },
                    'border-radius': '0px'
                  },
                  title: {
                    color: '#4c4c4c'
                  },
                  header: {
                    color: '#4c4c4c'
                  },
                  lineItems: {
                    color: '#4c4c4c'
                  },
                  subtotalText: {
                    color: '#4c4c4c'
                  },
                  subtotal: {
                    color: '#4c4c4c'
                  },
                  notice: {
                    color: '#4c4c4c'
                  },
                  currency: {
                    color: '#4c4c4c'
                  },
                  close: {
                    color: '#4c4c4c',
                    ':hover': {
                      color: '#4c4c4c'
                    }
                  },
                  empty: {
                    color: '#4c4c4c'
                  },
                  noteDescription: {
                    color: '#4c4c4c'
                  },
                  discountText: {
                    color: '#4c4c4c'
                  },
                  discountIcon: {
                    fill: '#4c4c4c'
                  },
                  discountAmount: {
                    color: '#4c4c4c'
                  }
                },
                googleFonts: ['Roboto']
              },
              toggle: {
                styles: {
                  toggle: {
                    'font-family': 'Roboto, sans-serif',
                    'background-color': '#000000',
                    ':hover': {
                      'background-color': '#000000'
                    },
                    ':focus': {
                      'background-color': '#000000'
                    }
                  },
                  count: {
                    'font-size': '17px'
                  }
                },
                googleFonts: ['Roboto']
              },
              lineItem: {
                styles: {
                  variantTitle: {
                    color: '#4c4c4c'
                  },
                  title: {
                    color: '#4c4c4c'
                  },
                  price: {
                    color: '#4c4c4c'
                  },
                  fullPrice: {
                    color: '#4c4c4c'
                  },
                  discount: {
                    color: '#4c4c4c'
                  },
                  discountIcon: {
                    fill: '#4c4c4c'
                  },
                  quantity: {
                    color: '#4c4c4c'
                  },
                  quantityIncrement: {
                    color: '#4c4c4c',
                    'border-color': '#4c4c4c'
                  },
                  quantityDecrement: {
                    color: '#4c4c4c',
                    'border-color': '#4c4c4c'
                  },
                  quantityInput: {
                    color: '#4c4c4c',
                    'border-color': '#4c4c4c'
                  }
                }
              }
            }
          })
        })
      }
    })()
  }

  render () {
    return (
      <div style={{ backgroundColor: '#fff', paddingBottom: 50 }}>
        <div>
          <div
            id='collection-component-1611178677078'
            className='grid2 fadein'
            style={{ margin: '0 auto' }}
          />
        </div>
        <div className='hashtag' style={{ marginTop: 20, textAlign: 'center' }}>
          <span id='fc' style={{ color: '#452274' }}>
            gear up
          </span>
        </div>
      </div>
    )
  }
}
