import React, { Component } from 'react'

export default class Login extends Component {
  state = {
    email: '',
    password: ''
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSub = e => {
    e.preventDefault()

    const { email, password } = this.state

    this.props.userLogin(email, password)
  }

  render () {
    return (
      <div
        aria-hidden={this.props.open ? 'false' : 'true'}
        aria-live='polite'
        aria-controls='modal'
        id='modal'
        className={`modal ${this.props.open ? 'modal-open' : 'modal-closed'}`}
      >
        <div aria-hidden='true' className='modal-overlay' />
        <div className='interior'>
          <span onClick={this.props.toggleLogin} className='close-modal'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='30px'
              height='30px'
              viewBox='0 0 24 24'
              fill='none'
              stroke='#fff'
              strokeWidth='3'
              strokeLinecap='square'
              strokeLinejoin='arcs'
            >
              <line x1='18' y1='6' x2='6' y2='18' />
              <line x1='6' y1='6' x2='18' y2='18' />
            </svg>
          </span>
          <h2>Sign In</h2>
          <form className='login-form' onSubmit={this.handleSub}>
            <input
              type='text'
              name='email'
              placeholder='Email'
              value={this.state.email}
              onChange={this.handleChange}
            />
            <input
              type='password'
              name='password'
              placeholder='Password'
              value={this.state.password}
              onChange={this.handleChange}
            />
            <button onClick={this.handleSub}>Sign In</button>
          </form>
        </div>
      </div>
    )
  }
}
