import React, { Component } from 'react'
import { Parallax } from 'react-scroll-parallax'
import { Link } from 'react-router-dom'
import Contact from './components/Contact'
import Mainsponsor from './components/Mainsponsors'
import Cab from './components/Cab'
import Shop from './components/Shop'

export default class App extends Component {
  render () {
    return (
      <div className='app'>
        <div className='context'>
          <div className='fishheader'>
            <Parallax y={[-30, 0]}>
              <img
                src='https://res.cloudinary.com/boernebass/image/upload/v1/home/header'
                alt='fish'
              />
            </Parallax>
            <div className='hero'>
              <h2>Boerne Bass Club</h2>
            </div>
          </div>
        </div>
        {/* <div className="header">
                    <Parallax y={[-0, 450]} >
                        <div className="fishsvg">
                            <img src={donut} alt="fish" />
                        </div>
                    </Parallax>
                    <Parallax y={[30, -50]} >
                        <div className="fishsvg">
                            <img src={donut} alt="fish" />
                        </div>
                    </Parallax>
                    <Parallax y={[0, 400]} >
                        <div className="fishsvg">
                            <img src={donut} alt="fish" />
                        </div>
                    </Parallax>
                    <Parallax y={[300, -30]} >
                        <div className="fishsvg">
                            <img src={donut} alt="fish" />
                        </div>
                    </Parallax>
                    <Parallax y={[-20, 300]} >
                        <div className="fishsvg">
                            <img src={donut} alt="fish" />
                        </div>
                    </Parallax>
                    <Parallax y={[40, 220]} >
                        <div className="fishsvg">
                            <img src={donut} alt="fish" />
                        </div>
                    </Parallax>
                    <Parallax y={[40, 550]} >
                        <div className="fishsvg">
                            <img src={donut} alt="fish" />
                        </div>
                    </Parallax>
                    <Parallax y={[40, 444]} >
                        <div className="fishsvg">
                            <img src={donut} alt="fish" />
                        </div>
                    </Parallax>
                </div> */}
        <div className='about'>
          <h2>About Us</h2>
          <div className='about-content grid2'>
            <div className='group-image'>
              {/* <Parallax y={[-15, 55]}> */}
              <img
                src='https://res.cloudinary.com/boernebass/image/upload/v1/home/group'
                alt='Boerne Bass Club Group'
              />
              {/* </Parallax> */}
            </div>
            {/* <Parallax y={[90, 0]}> */}
            <div className='about-p'>
              <div>
                <h3>Mission Statement</h3>
                Our mission is to create a sustainable bass fishing club for
                area youth, keeping intact Christian oriented ideals and guiding
                principles and in doing so, to develop future leaders in our
                community who have outstanding character, values and share a
                love for the outdoors.
              </div>
            </div>
            {/* </Parallax> */}
          </div>
        </div>
        <div className='grabber'>
          <h2>Schedule</h2>
          <div className='grabber-content grid2'>
            <div className='hashtag'>
              {/* <Parallax y={[-50, 20]}> */}
              <span id='fc'>
                #BOERNE
                <br />
                BASSCLUB
              </span>
              {/* </Parallax> */}
            </div>
            <div className='schedule'>
              {/* <Parallax y={[100, 10]}> */}
              <div className='schedule-image'>
                <img
                  src='https://res.cloudinary.com/boernebass/image/upload/v1/home/schedule'
                  alt='Schedule'
                />
              </div>
              {/* </Parallax> */}
            </div>
          </div>
        </div>
        <div className='shop'>
          <h2>shop</h2>
          <Shop />
        </div>
        <div className='team'>
          <h2>Cabinet</h2>
          <div className='team-content grid2'>
            <Cab cabinet={this.props.cabinet} />
            <div className='team-description about-p'>
              {/* <Parallax y={[100, 0]}> */}
              {/* <img src={crew} alt='team' /> */}
              <div>
                <h3>Our Goals</h3>Our goals for this season are to grow our club
                to its maximum potential, compete at our highest level and
                sportsmanship, get to know our fishing community and do the best
                we can to represent our small town!
              </div>
              {/* </Parallax> */}
            </div>
          </div>
        </div>

        <div className='main-sponsor'>
          <h2>Sponsors</h2>
          <div className='main-sponsor-content'>
            <Mainsponsor />
          </div>
          <Link className='all-sponsors' to='/sponsors'>
            View All Sponsors
          </Link>
          <br />
        </div>
        <div className='contact'>
          <h2>Contact</h2>
          <div className='contact-content grid2'>
            <div className='contact-image'>
              <img
                src='https://res.cloudinary.com/boernebass/image/upload/v1/home/contact'
                alt='Man Holding Fish'
              />
            </div>
            <div className='contact-parallax about-p'>
              <Contact />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
